
import Vue from 'vue'
import $ from 'jquery/dist/jquery.slim'
import Geolocator from '@/lib/geolocator'
import { debounce, throttle } from 'underscore'

export default {
  name: 'AddressPicker',
  data: -> {
    address: {
      formatted: '',
      street_number: ''
      route: ''
      latitude: null,
      longitude: null,
      postal_code: '',
      sublocality: '',
      unit_or_floor: ''
    },
    autocomplete_service: null,
    geocoder: null,
    loading: false,
    map_instance: null,
    map_position: null,
    open: false,
    address_model: { description: null, value: null },
    query_string: null,
    query_results: [],
    search: '',
    ignore_map_selection: false
    predictions_timeout: null,
    debounced_geocode_mapped_position: debounce(@geocode_mapped_position, 500)
    throttled_fetch_predictions: throttle(@fetch_predictions, 500)
  },

  computed: {
    default_map_position: ->
      {
        lat: Number(@$store.getters['application/default_position'].latitude),
        lng: Number(@$store.getters['application/default_position'].longitude)
      }

    visible: -> @$store.state.address_picker.open
  }

  mounted: ->
    @initialize_google_libs()

  beforeDestroy: ->
    @throttled_fetch_predictions.cancel()
    @debounced_geocode_mapped_position.cancel()

  watch: {
    map_position: (value) ->
      @debounced_geocode_mapped_position()

    open: (open) ->
      new Geolocator(@render_map, @render_map).locate() if open

    query_string: (q) ->
      return if not !!q
      if @skip_autocomplete then @skip_autocomplete = false else @throttled_fetch_predictions()

    visible: (value) ->
      @open = value
  }

  methods: {
    initialize_google_libs: ->
      if window.google && window.google.maps && window.google.maps.places
        @geocoder = new window.google.maps.Geocoder()
        @autocomplete_service = new window.google.maps.places.AutocompleteService()
      else
        console.error('Google Maps JavaScript API not loaded')

    build_map_starting_position: (user_location) ->
      if user_location? and !user_location.code
        { lat: user_location.latitude, lng: user_location.longitude }
      else
        @default_map_position

    geocode_mapped_position: ->
      return @skip_map_selection = false if @skip_map_selection

      @loading = true
      @geocoder.geocode({
        location: @map_position,
        region: @$store.getters['application/country']
      })
      .then (response) =>
        place = response.results[0]
        if place
          @parse_google_address(place)
          prediction = { description: place.formatted_address }
          @skip_autocomplete = true
          @query_results = [prediction]
          @address_model = prediction
        @loading = false
      .catch (error) ->
        console.error 'geocode_mapped_position', error
        @loading = false

    geocode_searched_place: ->
      @loading = true
      @geocoder.geocode({ placeId: @address_model.place_id })
      .then (response) =>
        place = response.results[0]
        if place
          @skip_map_selection = true
          @parse_google_address(place)
          @map_instance.panTo(place.geometry.location)
          @map_instance.setZoom(15)
        @loading = false
      .catch (error) -> console.error 'geocode_searched_place', error

    geocode_input_place: ->
      @loading = true
      formatted_address = "#{@address.street_number} #{@address.route}, #{@address.sublocality}, #{@address.postal_code}"
      @prefer_input = true
      @geocoder.geocode({ address: formatted_address })
      .then (response) =>
        place = response.results[0]
        if place
          @skip_map_selection = true
          @parse_google_address(place)
          @map_instance.panTo(place.geometry.location)
          @map_instance.setZoom(15)
        @loading = false
      .catch (error) -> console.error 'geocode_searched_place', error


    fetch_predictions: ->
      @loading = true
      @autocomplete_service.getPlacePredictions({
        componentRestrictions: { country: @$store.getters['application/country'] },
        input: @query_string,
      })
      .then (response) =>
        @query_results = response.predictions || []
        @loading = false
      .catch (error) -> console.error 'fetch_predictions.catch', error

    pick_address: ->
      @$emit('picked', @address)
      @dismiss()

    dismiss: ->
      @address.unit_or_floor = null
      @$store.commit('address_picker/dismiss')

    render_map: (position) ->
      @map_instance = new window.google.maps.Map(@$refs.the_map, {
        zoom: 10,
        center: @build_map_starting_position(position),
        disableDefaultUI: true
      })
      google.maps.event.addListener @map_instance, 'idle', => @set_map_position()
      $('<div/>', { class: 'center-marker' }).appendTo($(@map_instance.getDiv()))

    set_map_position: ->
      position = @map_instance.getCenter()
      @map_position = { lat: position.lat(), lng: position.lng() }

    parse_google_address: (place) ->
      @address.formatted = if @address_model.description then @address_model.description else place.formatted_address
      @address.latitude = place.geometry.location?.lat()
      @address.longitude = place.geometry.location?.lng()
      @address.street_number = @read_google_place(place, 'street_number') unless @prefer_input
      @address.route = @read_google_place(place, 'route')
      @address.postal_code = @read_google_place(place, 'postal_code')
      @address.sublocality = @read_google_place(place, 'sublocality')
      @address.locality = @read_google_place(place, 'locality')
      @prefer_input = false

    read_google_place: (place, attribute) ->
      place.address_components.find((c) -> attribute in c.types)?.long_name
  }
}
